import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-buffalo-new-york.png'
import image0 from "../../images/cities/scale-model-of-buffalo-naval-&-military-park-in-buffalo-new-york.png"
import image1 from "../../images/cities/scale-model-of-buffalo-cobblestone-district-in-buffalo-new-york.png"
import image2 from "../../images/cities/scale-model-of-buffalo-double-decker-tours-in-buffalo-new-york.png"
import image3 from "../../images/cities/scale-model-of-buffalo-and-erie-county-botanical-gardens-in-buffalo-new-york.png"
import image4 from "../../images/cities/scale-model-of-frank-lloyd-wright's-martin-house-complex-in-buffalo-new-york.png"
import image5 from "../../images/cities/scale-model-of-buffalo-place-inc-in-buffalo-new-york.png"
import image6 from "../../images/cities/scale-model-of-buffalo-transportation-pierce-arrow-museum-in-buffalo-new-york.png"
import image7 from "../../images/cities/scale-model-of-st-joseph-cathedral-&-rectory-in-buffalo-new-york.png"
import image8 from "../../images/cities/scale-model-of-guaranty-building-in-buffalo-new-york.png"
import image9 from "../../images/cities/scale-model-of-old-fort-erie-in-buffalo-new-york.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Buffalo'
            state='New York'
            image={image}
            lat='42.88644679999999'
            lon='-78.8783689'
            attractions={ [{"name": "Buffalo Naval & Military Park", "vicinity": "Military Park 1 Naval, Marina Park S, Buffalo", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.8777009, "lng": -78.8796097}, {"name": "Buffalo Cobblestone District", "vicinity": "Illinois St, Buffalo", "types": ["point_of_interest", "establishment"], "lat": 42.8746793, "lng": -78.87496010000001}, {"name": "Buffalo Double Decker Tours", "vicinity": "1 Marine Dr, Buffalo", "types": ["point_of_interest", "establishment"], "lat": 42.8778358, "lng": -78.87908399999998}, {"name": "Buffalo and Erie County Botanical Gardens", "vicinity": "2655 South Park Ave, Buffalo", "types": ["museum", "park", "point_of_interest", "establishment"], "lat": 42.8280608, "lng": -78.82565569999997}, {"name": "Frank Lloyd Wright's Martin House Complex", "vicinity": "125 Jewett Pkwy, Buffalo", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.9363116, "lng": -78.8479724}, {"name": "Buffalo Place Inc", "vicinity": "671 Main St, Buffalo", "types": ["point_of_interest", "establishment"], "lat": 42.892187, "lng": -78.8716928}, {"name": "Buffalo Transportation Pierce Arrow Museum", "vicinity": "263 Michigan Ave, Buffalo", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.8790979, "lng": -78.86947129999999}, {"name": "St Joseph Cathedral & Rectory", "vicinity": "50 Franklin St, Buffalo", "types": ["church", "place_of_worship", "point_of_interest", "establishment"], "lat": 42.8827984, "lng": -78.87828350000001}, {"name": "Guaranty Building", "vicinity": "140 Pearl St, Buffalo", "types": ["point_of_interest", "establishment"], "lat": 42.8830057, "lng": -78.8767891}, {"name": "Old Fort Erie", "vicinity": "350 Lakeshore Rd, Fort Erie", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.89463809999999, "lng": -78.9239321}] }
            attractionImages={ {"Buffalo Naval & Military Park":image0,"Buffalo Cobblestone District":image1,"Buffalo Double Decker Tours":image2,"Buffalo and Erie County Botanical Gardens":image3,"Frank Lloyd Wright's Martin House Complex":image4,"Buffalo Place Inc":image5,"Buffalo Transportation Pierce Arrow Museum":image6,"St Joseph Cathedral & Rectory":image7,"Guaranty Building":image8,"Old Fort Erie":image9,} }
       />);
  }
}